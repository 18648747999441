import {ensureArray} from '@matchsource/utils';

export class LoadSuppliers {
  static readonly type = '[Suppliers] Load Suppliers';

  readonly bpGuids: MsApp.Guid[];

  constructor(bpGuids: MsApp.Guid | MsApp.Guid[]) {
    this.bpGuids = ensureArray(bpGuids);
  }
}

export class LoadedSuppliers {
  static readonly type = '[Suppliers] Loaded Suppliers';
}
