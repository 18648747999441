import {Injectable} from '@angular/core';
import {ensureArray, uniq} from '@matchsource/utils';
import {SupplierModel} from '@matchsource/models/suppliers';
import {forkJoin, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
import {SupplierControllerService} from '@matchsource/api-generated/orders';
import {fromDTO} from './suppliers.serializer';

@Injectable({
  providedIn: 'root',
})
export class SuppliersApiService {
  constructor(private readonly supplierControllerService: SupplierControllerService) {}

  private getSuppliers(bpGuids: MsApp.Guid | MsApp.Guid[]): Observable<SupplierModel[]> {
    const ids = uniq(ensureArray(bpGuids));

    if (isEmpty(ids)) {
      return of([]);
    }

    return this.supplierControllerService.getSuppliersByGuids({body: ids}).pipe(
      map(suppliers => {
        return suppliers.map(supplier => fromDTO(supplier));
      })
    );
  }

  getSuppliersRecursively(bpGuids: MsApp.Guid | MsApp.Guid[]): Observable<SupplierModel[]> {
    return this.getSuppliers(bpGuids).pipe(
      flatMap(suppliers => {
        const parentGuids = suppliers.filter(({groupBpGuid}) => !!groupBpGuid).map(({groupBpGuid}) => groupBpGuid);

        return forkJoin([
          of(suppliers),
          ...(parentGuids.length > 0 ? [this.getSuppliersRecursively(parentGuids)] : []),
        ]);
      }),
      map(values => {
        return values.flat();
      })
    );
  }
}
