import {Injectable} from '@angular/core';
import {Action, Actions, createSelector, ofAction, Selector, State, StateContext} from '@ngxs/store';
import {LoadedSuppliers, LoadSuppliers} from './suppliers.action';
import {SuppliersApiService} from '@matchsource/api/suppliers';
import {filter, finalize, take, tap} from 'rxjs/operators';
import {SupplierModel} from '@matchsource/models/suppliers';
import {append, patch} from '@ngxs/store/operators';
import {toMap, uniq} from '@matchsource/utils';

interface SuppliersMap {
  [key: string]: SupplierModel;
}

interface SuppliersStateModel {
  entities: SuppliersMap;
  loadingIds: string[];
}

@State<SuppliersStateModel>({
  name: 'suppliers',
  defaults: {
    entities: {},
    loadingIds: [],
  },
})
@Injectable()
export class SuppliersState {
  @Selector([SuppliersState])
  static entities({entities}: SuppliersStateModel): SuppliersMap {
    return entities;
  }

  @Selector([SuppliersState])
  static loadingIds({loadingIds}: SuppliersStateModel): string[] {
    return loadingIds;
  }

  @Selector([SuppliersState.loadingIds])
  static allLoaded(loadingIds: string[]): boolean {
    return loadingIds && !loadingIds.length;
  }

  static rootSuppliersByChildId(ids: MsApp.Guid[]) {
    ids = uniq(ids);
    return createSelector([this.entities], (entities: SuppliersMap) =>
      ids.reduce((rootSuppliers, id) => {
        let rootSupplier = entities[id];

        while (rootSupplier && rootSupplier.groupBpGuid) {
          rootSupplier = entities[rootSupplier.groupBpGuid];
        }

        rootSuppliers[id] = rootSupplier || null;

        return rootSuppliers;
      }, {})
    );
  }

  constructor(
    private readonly api: SuppliersApiService,
    private readonly actions$: Actions
  ) {}

  @Action(LoadSuppliers)
  loadSuppliers(ctx: StateContext<SuppliersStateModel>, {bpGuids}: LoadSuppliers) {
    if (bpGuids.length === 0) {
      return;
    }

    const {entities, loadingIds} = ctx.getState();
    const isAllLoaded = bpGuids.every(bpGuid => bpGuid in entities);

    if (isAllLoaded) {
      return;
    }

    const newBpGuids = bpGuids.filter(bpGuid => !(bpGuid in entities || loadingIds.includes(bpGuid)));

    if (newBpGuids.length === 0) {
      return this.actions$.pipe(
        ofAction(LoadedSuppliers),
        filter(() => {
          const {loadingIds: remainLoadingIds} = ctx.getState();

          return bpGuids.every(id => !(id in remainLoadingIds));
        }),
        take(1)
      );
    }

    ctx.setState(
      patch({
        loadingIds: append(newBpGuids),
      })
    );

    return this.api.getSuppliersRecursively(newBpGuids).pipe(
      tap(suppliers => {
        ctx.setState(
          patch({
            entities: patch(toMap(suppliers, 'bpGuid')),
          })
        );
      }),
      finalize(() => {
        const {loadingIds: currentLoadingIds} = ctx.getState();

        ctx.setState(
          patch({
            loadingIds: currentLoadingIds.filter(id => !newBpGuids.includes(id)),
          })
        );
        ctx.dispatch(new LoadedSuppliers());
      })
    );
  }
}
