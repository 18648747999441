import {Supplier} from '@matchsource/api-generated/orders';
import {SupplierModel} from '@matchsource/models/suppliers';

export const fromDTO = (input: Supplier): SupplierModel => {
  return {
    bpGuid: input.bpGuid,
    desc: input.desc,
    groupBpGuid: input.groupBpGuid,
  };
};
